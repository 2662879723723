<template>
  <div>
    <panel
      v-if="false"
      title="Users"
      initiallyExpanded
      collapsable>
      <div class="mt-30">
        <el-button
          type="primary"
          size="mini"
          disabled
          round>
          Block
        </el-button>
        <el-button
          type="primary"
          size="mini"
          disabled
          round>
          Add Sub-User
        </el-button>
        <el-button
          type="primary"
          size="mini"
          disabled
          round>
          Remove User
        </el-button>
      </div>

      <empty>There are no users.</empty>
    </panel>

    <panel title="User Log" collapsable>
      <data-list :list="userLogList" />
    </panel>
  </div>
</template>

<script>
import navigation from './navigation'

export default {
  computed: {
    user () {
      return this.$store.state.app.user
    },
    registration () {
      return this.user?.registration
    },

    registrationData () {
      return this.registration?.data
    },

    lastLogin () {
      return this.user.lastLoginData
    },

    userLogList () {
      if (!this.lastLogin) return

      return [
        {
          label: 'Last Login',
          text: this.$date(this.lastLogin.recorded)
        },
        {
          label: 'Data Transfer',
          text: this.$fileSize(this.lastLogin.transferred)
        },
        {
          label: 'IP Address',
          text: this.lastLogin.ipAddress
        }
      ]
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'My Access'
    })

    this.$store.commit('page/setTabs', navigation)
  }
}
</script>
